import UserStorage from './UserStorage';

const RouteData = {
    localRoute:[{
        id:'/',
        path: '/',
        name: 'home', 
        pageUrl: '/Home',
        url: '/',
        permission:'首页',
        children:[
            {
                id:'/console',
                path: '/console',
                name:'console',
                pageUrl:'/Console',
                url:'/console',
                permission:'工作台'
            }, {
                id:'/ticketType',
                path: '/ticketType',
                name:'ticketType',
                pageUrl:'/ticketCode/ticketType',
                url:'/ticketType',
                permission:'券类型管理'
            }, {
                id:'/redeemCode',
                path: '/redeemCode',
                name:'redeemCode',
                pageUrl:'/ticketCode/redeemCode',
                url:'/redeemCode',
                permission:'兑换码管理'
            }, {
                id:'/ticketManage',
                path: '/ticketManage',
                name:'ticketManage',
                pageUrl:'/ticketCode/ticketManage',
                url:'/ticketManage',
                permission:'券管理'
            }
        ]
    }, 
    {
        id: '/login',
        name:'login',
        path: '/login',
        pageUrl: '/Login',
        url: '/login', 
        permission: '登录'
    }],
    buildRoute:function(item,level){
        return {  
            path: item.id,
            component:() => import('../components' + item.pageUrl + '.vue'),
            meta:{
                title:item.permission,
                name:item.permission,
                id:item.id,
                url:item.url,
                level:level
            }
        }
    },
    getLocalRoute(){
        return this.localRoute;
    },
    createRoutes:function(){
        let localRouteArray = UserStorage.getRouteInfo();
        if(localRouteArray == null || localRouteArray.length == 0) localRouteArray = this.localRoute;
		console.log('localRouteArray: ',localRouteArray);
        let result = [];
        for(let i = 0;i<localRouteArray.length;i++){
            let item = localRouteArray[i];
            let route = this.buildRoute(item,0);
            route.children = [];
            if(item.hasOwnProperty('children')){
                for(let n = 0;n<item.children.length;n++){
                    let sub = item.children[n];
                    route.children.push(this.buildRoute(sub,1));
                }
            }
           result.push(route);
        } 
        return result;
    }
};

export default RouteData;
  