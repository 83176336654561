import axios from 'axios';
import UserStorage from './UserStorage'


const service = axios.create({
    // process.env.NODE_ENV === 'development' 来判断是否开发环境
    // easy-mock服务挂了，暂时不使用了
    // baseURL: 'https://www.easy-mock.com/mock/592501a391470c0ac1fab128',
    timeout: 120000
});

service.interceptors.request.use(
    config => {
        return config;
    },
    error => {
        console.log(error);
        return Promise.reject();
    }
);

service.interceptors.response.use(
    response => {
        if (response.status === 200) {
            return response;
        } else {
            Promise.reject();
        }
    },
    error => {
        const status = (error.response && error.response.status && error.response.status) || '';
        const data = (error.response && error.response.data) || {};
        if (data.message) {
            return Promise.reject(data.message);
        }

        if (
            error.code == 'ECONNABORTED' &&
            error.message.indexOf('timeout') != -1
        ) {
            return Promise.reject('请求超时~~');
        }
        if (status === 401) {
            UserStorage.clear();
            window.location.reload();
            return Promise.reject('登录过期,请重新登录');
        }
        if (status === 404) {
            return Promise.reject('接口404报错');
        }
        if (status === 500) {
            return Promise.reject('接口404报错');
        }
        return Promise.reject('未知错误');
    }
);

export default service;
