//性别分布图标
export function getGenderCounterData(formData) {
    return {
        title: {
            text: '',
            subtext: '',
            left: 'center'
        },
        tooltip: {
            trigger: 'item'
        },
        legend: {
            orient: 'horizontal',
            left: 'center',
            bottom: 10
        },
        series: [{
            name: '',
            type: 'pie',
            radius: '50%',
            data: [{
                value: formData.gender_boys,
                name: '男'
            },
            {
                value: formData.gender_girls,
                name: '女'
            },
            {
                value: formData.gender_unknown,
                name: '未知'
            }
            ],
            emphasis: {
                itemStyle: {
                    shadowBlur: 10,
                    shadowOffsetX: 0,
                    shadowColor: 'rgba(0, 0, 0, 0.5)'
                }
            },
            itemStyle: {
                color: function (colors) {
                    var colorList = [
                        '#3399ff',
                        '#B26DA2',
                        '#D9D9D9'
                    ];
                    return colorList[colors.dataIndex];
                }
            },
            label: {
                show: true,
                position: 'inner',
                fontWeight: 300,
                fontSize: 10,
                formatter: '{d}%'
            }
        }]
    };
}
//城市数据排行
export function getCityCounterData(formData) {
    return {
        xAxis: [{
            show: true,
            data: formData.citys.split(","),
            axisLabel: {
                interval: 0,
                rotate: 30
            }
        }],
        yAxis: [{
            show: true,
            axisLabel: {
                formatter: '{value}%'
            }
        }],
        series: [{
            name: '',
            type: 'bar',
            itemStyle: {
                normal: {
                    color: function (params) {
                        // build a color map as your need.
                        var colorList = [
                            '#0299B5', '#0299B5', '#0299B5', '#0299B5', '#0299B5',
                            '#0299B5', '#0299B5', '#0299B5', '#0299B5', '#0299B5',
                            '#0299B5', '#0299B5', '#0299B5', '#0299B5', '#0299B5',
                            '#0299B5', '#0299B5', '#0299B5', '#0299B5', '#0299B5',
                            '#0299B5', '#0299B5', '#0299B5', '#0299B5', '#0299B5',
                            '#0299B5', '#0299B5', '#0299B5', '#0299B5', '#0299B5',
                            '#0299B5', '#0299B5', '#0299B5', '#0299B5', '#0299B5',
                            '#0299B5', '#0299B5', '#0299B5', '#0299B5', '#0299B5',
                            '#0299B5', '#0299B5', '#0299B5', '#0299B5', '#0299B5'
                        ];
                        return colorList[params.dataIndex]
                    },
                    label: {
                        show: true,
                        position: 'top',
                        formatter: '{c}%'
                    }
                }
            },
            data: formData.city_counts.split(",")
        }]
    };
}
//等级分布
export function getLevelCounterData(formData) {
    return {
        xAxis: [{
            show: true,
            data: formData.names.split(","),
            axisLabel: {
                interval: 0,
                rotate: 30
            }
        }],
        yAxis: [{
            show: true,
            axisLabel: {
                formatter: '{value}%'
            }
        }],
        series: [{
            name: '',
            type: 'bar',
            itemStyle: {
                normal: {
                    color: function (params) {
                        // build a color map as your need.
                        var colorList = [
                            '#D69C07', '#D69C07', '#D69C07', '#D69C07', '#D69C07',
                            '#D69C07', '#D69C07', '#D69C07', '#D69C07', '#D69C07',
                            '#D69C07', '#D69C07', '#D69C07', '#D69C07', '#D69C07',
                            '#D69C07', '#D69C07', '#D69C07', '#D69C07', '#D69C07',
                            '#D69C07', '#D69C07', '#D69C07', '#D69C07', '#D69C07',
                            '#D69C07', '#D69C07', '#D69C07', '#D69C07', '#D69C07',
                            '#D69C07', '#D69C07', '#D69C07', '#D69C07', '#D69C07',
                            '#D69C07', '#D69C07', '#D69C07', '#D69C07', '#D69C07',
                            '#D69C07', '#D69C07', '#D69C07', '#D69C07', '#D69C07'
                        ];
                        return colorList[params.dataIndex]
                    },
                    label: {
                        show: true,
                        position: 'top',
                        formatter: '{c}%'
                    }
                }
            },
            data: formData.level_counts.split(",")
        }]
    };
}

//获取24小时统计活动
export function getActiveCounterData(formData, todayLineColor, yesterLineColor) {
    return {
        color: ['#80FFA5', '#00DDFF', '#37A2FF', '#FF0087', '#FFBF00'],
        title: {
            text: ''
        },
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'cross',
                label: {
                    backgroundColor: '#6a7985'
                }
            }
        },
        legend: {
            data: ['今日', '昨日']
        },
        toolbox: {
            feature: {
                saveAsImage: {}
            }
        },
        grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
        },
        xAxis: [{
            type: 'category',
            boundaryGap: false,
            data: ['00:00', '01:00', '02:00', '03:00', '04:00', '05:00', '06:00', '07:00', '08:00', '09:00',
                '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00',
                '20:00', '21:00', '22:00', '23:00', '24:00'
            ]
        }],
        yAxis: [{
            type: 'value'
        }],
        series: [{
            name: '今日',
            type: 'line',
            // stack: 'Total',
            smooth: true,
            lineStyle: {
                width: 0
            },
            showSymbol: false,
            areaStyle: {
                opacity: 0.8,
                color: todayLineColor
            },
            emphasis: {
                focus: 'series'
            },
            data: formData.today_count
        },
        {
            name: '昨日',
            type: 'line',
            // stack: 'Total',
            smooth: true,
            lineStyle: {
                width: 0
            },
            showSymbol: false,
            areaStyle: {
                opacity: 0.8,
                color: yesterLineColor
            },
            emphasis: {
                focus: 'series'
            },
            data: formData.yester_count
        }
        ]
    };
}


//按月统计，显示上月和本月
export function getActiveCounterDayOfDay(formData, todayLineColor, yesterLineColor) {
    return {
        color: ['#80FFA5', '#00DDFF', '#37A2FF', '#FF0087', '#FFBF00'],
        title: {
            text: ''
        },
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'cross',
                label: {
                    backgroundColor: '#6a7985'
                }
            }
        },
        legend: {
            data: ['本月', '上月']
        },
        toolbox: {
            feature: {
                saveAsImage: {}
            }
        },
        grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
        },
        xAxis: [{
            type: 'category',
            boundaryGap: false,
            data: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15',
                '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30',
                '31'
            ]
        }],
        yAxis: [{
            type: 'value'
        }],
        series: [{
            name: '本月',
            type: 'line',
            // stack: 'Total',
            smooth: true,
            lineStyle: {
                width: 0
            },
            showSymbol: false,
            areaStyle: {
                opacity: 0.8,
                color: todayLineColor
            },
            emphasis: {
                focus: 'series'
            },
            data: formData.today_count
        },
        {
            name: '上月',
            type: 'line',
            // stack: 'Total',
            smooth: true,
            lineStyle: {
                width: 0
            },
            showSymbol: false,
            areaStyle: {
                opacity: 0.8,
                color: yesterLineColor
            },
            emphasis: {
                focus: 'series'
            },
            data: formData.yester_count
        }
        ]
    };
}


//按年，今年去年
export function getActiveCounterOfMonth(formData, todayLineColor, yesterLineColor) {
    return {
        color: ['#80FFA5', '#00DDFF', '#37A2FF', '#FF0087', '#FFBF00'],
        title: {
            text: ''
        },
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'cross',
                label: {
                    backgroundColor: '#6a7985'
                }
            }
        },
        legend: {
            data: ['今年', '去年']
        },
        toolbox: {
            feature: {
                saveAsImage: {}
            }
        },
        grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
        },
        xAxis: [{
            type: 'category',
            boundaryGap: false,
            data: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12']
        }],
        yAxis: [{
            type: 'value'
        }],
        series: [{
            name: '今年',
            type: 'line',
            // stack: 'Total',
            smooth: true,
            lineStyle: {
                width: 0
            },
            showSymbol: false,
            areaStyle: {
                opacity: 0.8,
                color: todayLineColor
            },
            emphasis: {
                focus: 'series'
            },
            data: formData.today_count
        },
        {
            name: '去年',
            type: 'line',
            // stack: 'Total',
            smooth: true,
            lineStyle: {
                width: 0
            },
            showSymbol: false,
            areaStyle: {
                opacity: 0.8,
                color: yesterLineColor
            },
            emphasis: {
                focus: 'series'
            },
            data: formData.yester_count
        }
        ]
    };
}


//其他症状占比
export function getSymptomProportionData(formData) {
    const symptomNameList = formData.map(item => item.symptomName);
    const manCountList = formData.map(item => item.manCount);
    const womanCountList = formData.map(item => item.womanCount);
    return {
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'shadow'
            }
        },
        legend: {
            data: ['男', '女'],
        },
        xAxis: {
            type: 'category',
            data: symptomNameList, // 选择时间段的月份
        },
        yAxis: {
            type: 'value'
        },
        series: [{
            name: '男',
            type: 'bar',
            stack: '人数',
            data: manCountList, // 柱状
            itemStyle: {
                color: '#165DFF',
            },
            barMaxWidth: 40,
            animationDelay: function (idx) {
                return idx * 100
            },
        },
        {
            name: '女',
            type: 'bar',
            stack: '人数',
            data: womanCountList, // 柱状
            itemStyle: {
                color: '#FF7816',
            },
            barMaxWidth: 40,
            animationDelay: function (idx) {
                return idx * 100
            },
        }],
    }
}

// 单项产品选择占比（TOP 05）
export function getSingleProductData(formData) {
    const dataList = formData.map(item => ({
        value: item.customerNum || 0,
        name: item.productName,// 产品名
    }));
    const valueAllNumber = dataList.reduce((sum, item) => sum + item.value, 0)
    return {
        tooltip: {
            trigger: 'item'
        },
        legend: {
            orient: 'vertical',
            bottom: 'bottom'
        },
        label: {
            alignTo: 'edge',
            formatter: function (params) {
                return `${params.name}${parseInt(params.value / valueAllNumber * 100)}%`
            },
            minMargin: 5,
            edgeDistance: 10,
            lineHeight: 15,
            rich: {
                time: {
                    fontSize: 10,
                    color: '#999'
                }
            }
        },
        series: [
            {
                name: 'Access From',
                type: 'pie',
                radius: '50%',
                data: dataList,
                emphasis: {
                    itemStyle: {
                        shadowBlur: 10,
                        shadowOffsetX: 0,
                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                    }
                }
            }
        ]
    }
}

// 产品人均使用瓶数
export function getProductPerCapitaData(formData) {
    const nameList = formData.map(item => item.name);//产品名
    const bottlesNumList = formData.map(item => item.bottlesNum);//使用瓶数

    return {
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'shadow'
            }
        },
        xAxis: {
            type: 'category',
            data: nameList,
        },
        yAxis: {
            type: 'value'
        },
        series: [{
            type: 'bar',
            data: bottlesNumList,
            itemStyle: {
                color: '#165DFF',
            },
            barMaxWidth: 40,
            animationDelay: function (idx) {
                return idx * 100
            },
        }],
    }
}

//脱发区域数据
export function getAlopeciaAreaData(formData) {
    const growScheduleNameList = formData.map(item => item.growScheduleName);
    const manCountList = formData.map(item => item.manCount);
    const womanCountList = formData.map(item => item.womanCount);
    return {
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'shadow'
            }
        },
        legend: {
            data: ['男', '女'],
        },
        xAxis: {
            type: 'category',
            data: growScheduleNameList, // 选择时间段的月份
        },
        yAxis: {
            type: 'value'
        },
        series: [{
            name: '男',
            type: 'bar',
            stack: '人数',
            data: manCountList, // 柱状
            itemStyle: {
                color: '#165DFF',
            },
            barMaxWidth: 40,
            animationDelay: function (idx) {
                return idx * 100
            },
        },
        {
            name: '女',
            type: 'bar',
            stack: '人数',
            data: womanCountList, // 柱状
            itemStyle: {
                color: '#FF7816',
            },
            barMaxWidth: 40,
            animationDelay: function (idx) {
                return idx * 100
            },
        }],
    }
}

// 打卡数据
export function getClockInData(formData, type) {
    const timeList = formData.map(item => item.time);
    const rangeList = formData.map(item => item.range || 0);
    const manCountList = formData.map(item => item.manCount);
    const womanCountList = formData.map(item => item.womanCount);
    const linkRelativeList = formData.map(item => (item.linkRelative ? item.linkRelative * 100 : 0));
    return {
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'cross',
                label: {
                    backgroundColor: '#6a7985'
                }
            }
        },
        legend: {
            data: ['日打卡人数/女', '日打卡人数/男', '日环比'],
        },
        xAxis: {
            type: 'category',
            boundaryGap: false,
            data: type == 1 ? rangeList : timeList, // 选择时间段的月份
        },
        yAxis: [{
            type: 'value',
            name: '人数',
        }, {
            type: 'value',
            name: '环比(%)',
        }],
        series: [{
            name: '日打卡人数/女',
            data: womanCountList, // 柱状
            type: 'line',
            stack: 'Total',
            smooth: true,
            lineStyle: {
                width: 0
            },
            showSymbol: false,
            areaStyle: {
                opacity: 0.8,
                color: 'rgb(128, 255, 165)'
            },
            emphasis: {
                focus: 'series'
            },
        },
        {
            name: '日打卡人数/男',
            data: manCountList, // 柱状
            type: 'line',
            stack: 'Total',
            smooth: true,
            lineStyle: {
                width: 0
            },
            showSymbol: false,
            areaStyle: {
                opacity: 0.8,
                color: 'rgb(1, 191, 236)'
            },
            emphasis: {
                focus: 'series'
            },
        }, {
            name: '日环比',
            type: 'line',
            yAxisIndex: 1,
            data: linkRelativeList, // 对应上面数据的线性月环比
            itemStyle: {
                color: '#3BA1FF',
            },
            lineStyle: {
                width: 2,
                type: 'solid',
                shadowBlur: 10,
                shadowColor: 'rgba(0,0,0,0.2)',
                shadowOffsetX: 3,
                shadowOffsetY: 3,
            },
            // 新增配置项，用于控制动画速度和循环次数
            animationDuration: 2000,
            animationEasing: 'linear',
            animationDelay: function (idx) {
                return 100 * idx
            },
            animationRepeat: true,
        }],
    }
}

//商品兑换统计
export function productsSellStatistics(formData) {
  const nameList = formData.map((item) => item.name) //产品名
  const sellNumList = formData.map((item) => item.sellNum) //使用瓶数

  return {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',
      },
    },
    xAxis: {
      type: 'category',
      data: nameList,
    },
    yAxis: {
      type: 'value',
    },
    series: [
      {
        type: 'bar',
        data: sellNumList,
        itemStyle: {
          color: '#165DFF',
        },
        barMaxWidth: 40,
        animationDelay: function (idx) {
          return idx * 100
        },
      },
    ],
  }
}

