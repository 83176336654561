<template>
    <div class="tags" id="tags">
        <!-- <ul id="tags-ul">
            <li class="tags-li" :style="tagLiStyle" v-for="(item,index) in tagsList" :class="{'active': isActive(item)}" :key="index" @click="showItem(item,index)"> 
                <div class="tags-li-text">{{item.name}}</div> 
                <el-icon @click.stop="closeTab(index)" class="el-icon--right"><Close/></el-icon>
            </li>
        </ul> -->
        <div class="tab-style" id="tab-list" ref="tabList">
            <el-tag v-for="(item,index) in tagsList" :style="tagLiStyle" :key="index" class="mx-1 tags-li" :class="{'active': isActive(item)}" closable :disable-transitions="false" @click="showItem(item,index)" @close="closeTab(index)">{{item.name}}</el-tag>
        </div>
        <div style="height: 43px;line-height: 43px;box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);width:120px;border:solid 2px #0299B5;" id="closeTagDiv">
            <el-dropdown size="large" @command="handMenu" style="height: 43px;line-height:43px;color:#0299B5;">
                <span class="el-dropdown-link" style="cursor: pointer;font-family: 'PingFang SC';font-style: normal;font-weight: 600;font-size: 14px;">
                    标签选项<el-icon class="el-icon--right"><CaretBottom/></el-icon>
                </span>
                <template #dropdown>
                    <el-dropdown-menu aria-expanded=true>
                        <el-dropdown-item command="closeOther" class="el-dropdown-item" style="height:20px;color: #606266;font-size: 12px;">关闭其他</el-dropdown-item>
                        <el-dropdown-item command="closeAll" class="el-dropdown-item" :divided=true style="color: #606266;font-size: 12px;">关闭所有</el-dropdown-item>
                    </el-dropdown-menu>
                </template>
            </el-dropdown>
        </div>
        <!-- <el-icon @click="testAddTages"><CirclePlus /></el-icon> -->
    </div>
</template>
<script>
import {Base64} from 'js-base64'
import {Close,CirclePlus,CaretBottom} from '@element-plus/icons-vue'
export default {
    components:{
        Close,CirclePlus,CaretBottom
    },
    data:function(){
        return {
           tagsList:[
                
           ], 
           tagLiStyle:""     //tab的文字样式
        }
    },
    methods:{
        isActive(item) {   
            return item.id == this.$route.meta.id;
        }, 
        //关闭其他说着关闭所有
        handMenu(command){
            if(command == 'closeOther'){
                const curItem = this.tagsList.filter(item => { 
                    return item.id == this.$route.meta.id;
                })
                this.tagsList = curItem;
                this.changeTabSize(0);
            }else if(command == 'closeAll'){
                if(this.tagsList.length == 1 && this.tagsList[0].name == "工作台"){
                    return;
                }
                
                this.tagsList = [];
                this.$router.push('/console');
                this.changeTabSize(0);
            }
        },
        //点击tab,显示当前的
        showItem(item,index){
            this.$router.push({
                path:item.id,
                meta:{keepAlive:true}
            });
            // this.$emit('tags', this.tagsList);
            // this.changeTabSize(index);
        },
        //关闭单个
        closeTab(index){
            //最后的索引
            let lastIndex = this.tagsList.length - 1;
            if(lastIndex == 0){
                if(this.tagsList[0].name == "工作台"){
                    return;
                }
                this.tagsList = [];
                this.$router.push({path:'/console'});
                // this.$emit('tags', this.tagsList);
                this.changeTabSize(0);
                return;
            }
            //要删除的menu
            let delItem = this.tagsList[index];
            //不是当前显示的
            if(!this.isActive(delItem)){
                this.tagsList.splice(index,1);
                // this.$emit('tags', this.tagsList);
                this.changeTabSize(index);
                return;
            } 

            //获取下一个要显示的
            let nextItem = null;
            if(index < lastIndex) nextItem = this.tagsList[index + 1];
            else nextItem = this.tagsList[index - 1];
             
            //显示需要显示的
            this.$router.push({
                path:nextItem.id
            });
            //删除在显示的
            this.tagsList.splice(index,1);
             
            // this.$emit('tags', this.tagsList);
            this.changeTabSize(index);
        },
        // 设置标签
        setTags(route){
            // const isExist = this.tagsList.some(item => { 
            //     return item.id == route.meta.id;
            // })
            let index = -1;
            for(let i = 0;i<this.tagsList.length;i++){
                if(this.tagsList[i].id == route.meta.id){
                    index = i;
                    break;
                }
            }
            if(index == -1){  
                let item = {
                    id:route.meta.id,
                    name:route.meta.name,
                    url:route.meta.url
                };
                this.tagsList.push(item);
            }
            // this.$emit('tags', this.tagsList); 
            this.changeTabSize(index);
        },
        //调整tab的大小,index 0第一个，-1最后一个
        changeTabSize(index){
            let tabList = document.getElementById("tab-list");
            if(tabList == null) return;
            let clientWidth = tabList.clientWidth;
            let showNum = (clientWidth + 86 - 1) / 86;
            let num = this.tagsList.length;

            this.$nextTick(()=>{
                if(index == -1 || index >= num - showNum / 3 ){
                    tabList.scrollLeft = clientWidth;
                }else if(index == 0 || index <= showNum / 3){
                    tabList.scrollLeft = 0;
                }else{ 
                    tabList.scrollLeft = clientWidth / num * index;
                }
            }); 
        }
    },
    watch:{
        $route(newValue){ 
            if(newValue.meta.level == 1){ 
                this.setTags(newValue);
            }
        }
    },
    created(){ 
        //刷新的时候保留当前的
        if(this.$route.meta.level == 1){ 
            this.setTags(this.$route); 
        } else{
            this.$router.push({path:'/console'});
        }
    }
}
</script>
<style scoped>
.tags {
    position: relative;
    /* position: absolute; */
    width: 100%;
    height: 50px;
    overflow: hidden;
    background: #fff;
    /* padding-right: 120px; */
    /* box-shadow: 0 5px 10px #ddd; */
    display: flex;
}
.tab-style{
    width:calc(100% - 120px);
    text-align:left;
    display: -webkit-box;
    overflow-x: scroll;
    overflow-y: hidden;
    /* margin-right: 2px;
    margin-left: 2px; */
}
/* *****************************滚动条样式********************************* */
::-webkit-scrollbar{
    /* width:16px; */
    height:5px;
    background-color:#F5F5F5;
}

/*定义滚动条轨道 内阴影+圆角*/
::-webkit-scrollbar-track{
    /* -webkit-box-shadow:inset 0 0 6px rgba(0,0,0,0.3); */
    /* border-radius:10px; */
    background-color:#F5F5F5;
}

/*定义滑块 内阴影+圆角*/
::-webkit-scrollbar-thumb{
    border-radius:2px;
    /* -webkit-box-shadow:inset 0 0 6px rgba(0,0,0,.3); */
    background-color:rgba(2, 153, 181,0.8);
    
} 
/* 鼠标悬停 */
::-webkit-scrollbar-thumb:hover{
    cursor: pointer;
}

.tags ul {
    /* box-sizing: border-box; */
    margin:0px;
    width: calc(100% - 100px);
    height: 100%;
    padding-left: 10px; 
}

.tags-li {
    float: left;
    /* margin: 3px 5px 2px 3px; */
    /* margin:8px 5px 7px 8px; */
    margin:8px 1px 7px 1px;
  
    font-size: 12px;
    overflow: hidden;
    cursor: pointer;
    height: 30px;
    line-height: 30px; 
    padding: 0 5px 0 12px;
    
    color: #666; 
    background: #FFFFFF;
    border: 1px solid #606266;
    border-radius: 5px;

    display: flex;
    align-items: center;
    text-align: center;
}

.tags-li:not(.active):hover {
    background: #f8f8f8;
}
.tags-li:not(.active):hover >>> .el-icon{
    color: #666;
    background: #ffffff;
}
.tags-li:not(.active) >>> .el-icon{
    color: #666;
}

.tags-li.active {
    color: #fff;
    background: #0299B5;
    border: 1px solid #0299B5;
}
.tags-li.active >>> .el-icon{
    color: #fff;
}

.tags-li-title {
    float: left;
    max-width: 80px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-right: 5px;
    color: #666;
    text-decoration:none;
}

.tags-li.active .tags-li-title {
    color: #fff;
}
.tags-li-text{ 
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.tags-close-box {
    position: absolute;
    right: 0;
    top: 0;
    box-sizing: border-box;
    padding-top: 1px;
    text-align: center;
    width: 110px;
    height: 30px;
    background: #fff;
    box-shadow: -3px 0 15px 3px rgba(0, 0, 0, .1);
    z-index: 10;
}
</style>
