import service from './index';
import UserStorage from './UserStorage';
import {showFullScreenLoading,tryHideFullScreenLoading} from './Loading'
import axios from 'axios'
let appEnv = myBrowser();
import { ElMessage } from 'element-plus'
 

/**
 * 创建请求格式
 * @param {*} body 请求内容
 */
export function buildParam(body){
    let aid = "WEB";
 
    let aidt = UserStorage.getUserInfo("aid");
    if(aidt != null && aidt != undefined && aidt.length > 0){
        aid = aidt;
    }

    let param = {
        head: {
          aid: aid,
          appEnv:appEnv,
          ver: "1.0",
          ln: "ch",
          mod: "api",
          de: UserStorage.getUserInfo("de"),
          userId: UserStorage.getUserInfo("userId"),
          userName: UserStorage.getUserInfo("userName"),
          chkCode: UserStorage.getUserInfo("token"),
          isViewMobile: UserStorage.getUserInfo("isViewMobile"),
        },
        con: body
    };
    return param;
}
export function getHeader(){
    let aid = "WEB";
    let aidTmp = UserStorage.getUserInfo("aid");
    if(aidTmp != null && aidTmp != undefined && aidTmp.length > 0){
        aid = aidTmp;
    }
    return {
        'token': UserStorage.getUserInfo("chkCode"),
        'X-Access-Token': aid + "&" + appEnv + "&1.0&ch&api&" + UserStorage.getUserInfo("de") + "&" + UserStorage.getUserInfo("userId") + "&" + UserStorage.getUserInfo("userName") + "&" + UserStorage.getUserInfo("chkCode")
    };
}



/**
 * 查询页面配置，
 * @param {*} url 页面配置查询的接口
 * @param {*} permission 权限表里的ID  
 * @param {*} funSuccess 
 * @param {*} funError 
 */
export function queryPageConfig(url,permission,funSuccess,funError){
    asyncPostLoading(url,{permission:permission},res => {
        if(res.data.head.st != 0){
            funError(res.data.head.msg); 
            return;
        }
        let data = res.data.body;
        let query = data.queryArea;
        let tableHeader = data.tableHeader;
        if(query === undefined || query == null || query == '' || tableHeader === undefined || tableHeader ==null || tableHeader == '') {
            funError('未能查询到配置信息');
            return;
        }
        let queryArray = JSON.parse(query);
        let tableHeaderArray = JSON.parse(tableHeader);
        funSuccess(queryArray,tableHeaderArray);
    },err => {
       funError(err);
    });
}

/**
 * 同步请求post
 * @param {*} url 
 * @param {*} requestBody 
 * @param {*} funSuccess 
 * @param {*} funError 
 */
 export async function syncPostLoading(url,requestBody) {

    showFullScreenLoading(null,null);
    let param = buildParam(requestBody);
    let headers = {
        headers:getHeader()
    }
    let res = await service.post(url,param,headers); 
    tryHideFullScreenLoading();
    return res;
};


/**
 * 异步请求post
 * @param {*} url 
 * @param {*} requestBody 
 * @param {*} funSuccess 
 * @param {*} funError 
 */
export async function asyncPostLoading(url,requestBody,funSuccess,funError,isLoading = true) {

    if (isLoading) showFullScreenLoading(null,null);
    let param = buildParam(requestBody);
    let headers = {
        headers:getHeader()
    }
    service.post(url,param,headers/*, config*/).then(res => {
        tryHideFullScreenLoading();
        funSuccess(res);
      }).catch(err => {
        tryHideFullScreenLoading();
        funError(err);
      });
};


/**
 * 异步请求put
 * @param {*} url 
 * @param {*} requestBody 
 * @param {*} funSuccess 
 * @param {*} funError 
 */
export async function asyncPutLoading(url,requestBody,funSuccess,funError) {

    showFullScreenLoading(null,null);
    let param = buildParam(requestBody);
    let headers = {
        headers:getHeader()
    }
    service.put(url,param,headers/*, config*/).then(res => {
        tryHideFullScreenLoading();
        funSuccess(res);
      }).catch(err => {
        tryHideFullScreenLoading();
        funError(err);
      });
};


/**
 * 异步导出数据
 */
export async function downloadFile(url, fileName, condition) {
	showFullScreenLoading(null,null);
	let info = buildParam(condition);
	axios.post(url, {
		...info
	}, {
		responseType: 'blob'
	}).then(res => {
		if (res.code === 200) {
			ElMessage.error(res.message)
			return
		}
		const blob = new Blob([res.data], {
			type: 'application/vnd.ms-excel; charset=utf-8'
		})
		const link = document.createElement('a')
		link.href = URL.createObjectURL(blob)
		link.download = fileName
		link.click()
		URL.revokeObjectURL(link.href)
		ElMessage.success('导出成功')
		tryHideFullScreenLoading();
	}).catch(err => {
		console.log('err: ',err);
		tryHideFullScreenLoading();
		ElMessage.error('导出失败')
	})
};


/**
 * 异步请求get
 * @param {*} url
 * @param {*} funSuccess
 * @param {*} funError
 */
export async function asyncGetLoading(url,funSuccess,funError) {

    showFullScreenLoading(null,null);
    let headers = {
        headers:getHeader()
    }
    service.get(url,headers/*, config*/).then(res => {
        tryHideFullScreenLoading();
        funSuccess(res);
      }).catch(err => {
        tryHideFullScreenLoading();
        funError(err);
      });
};


/**
 * 异步请求post
 * @param {*} url 
 * @param {*} requestBody 
 * @param {*} funSuccess 
 * @param {*} funError 
 */
export async function asyncPost(url,requestBody,funSuccess,funError) {
    let param = buildParam(requestBody);

    let headers = {
        headers:getHeader()
    }
    service.post(url,param,headers/*, config*/).then(res => {
        funSuccess(res);
      }).catch(err => {
        funError(err);
      });
};

function myBrowser() {
    var userAgent = navigator.userAgent; //取得浏览器的userAgent字符串
    var isOpera = userAgent.indexOf("Opera") > -1; //判断是否Opera浏览器
    var isIE = userAgent.indexOf("compatible") > -1
            && userAgent.indexOf("MSIE") > -1 && !isOpera; //判断是否IE浏览器
    var isEdge = userAgent.indexOf("Edge") > -1; //判断是否IE的Edge浏览器
    var isFF = userAgent.indexOf("Firefox") > -1; //判断是否Firefox浏览器
    var isSafari = userAgent.indexOf("Safari") > -1
            && userAgent.indexOf("Chrome") == -1; //判断是否Safari浏览器
    var isChrome = userAgent.indexOf("Chrome") > -1
            && userAgent.indexOf("Safari") > -1; //判断Chrome浏览器
 
    if (isIE) {
        var reIE = new RegExp("MSIE (\\d+\\.\\d+);");
        reIE.test(userAgent);
        var fIEVersion = parseFloat(RegExp["$1"]);
        if (fIEVersion == 7) {
            return "IE7";
        } else if (fIEVersion == 8) {
            return "IE8";
        } else if (fIEVersion == 9) {
            return "IE9";
        } else if (fIEVersion == 10) {
            return "IE10";
        } else if (fIEVersion == 11) {
            return "IE11";
        } else {
            return "0";
        }//IE版本过低
        return "IE";
    }
    if (isOpera) {
        return "Opera";
    }
    if (isEdge) {
        return "Edge";
    }
    if (isFF) {
        return "FF";
    }
    if (isSafari) {
        return "Safari";
    }
    if (isChrome) {
        return "Chrome";
    }
    
}