<template>
<el-config-provider :locale="locale">
  <div id="app"><router-view/></div>
</el-config-provider>
</template>

<script> 
import zhCn from 'element-plus/lib/locale/lang/zh-cn' //分页显示中文
export default {
  name: 'App',
 
  setup() { 
    const lang = () => {// eslint-disable-line no-unused-vars(如果运行时报错，就加上前面这行注释)
      location.reload()
    }
    
    document.body.style.setProperty('--el-color-primary', '#0299B5');//所有button input框线背景色的颜色
    document.body.style.setProperty('--el-color-primary-dark-2', '#0299B5'); //所有button input框线背景色的颜色
    document.body.style.setProperty('--el-switch-on-color', '#0299B5'); //开关开状态颜色
    document.body.style.setProperty('--el-switch-off-color', '#ff4949'); //开关关闭状态颜色，不起作用，因为源码中是boder的颜色，所以在每个开关中设置了--el-switch-off-color 为 #ff4949
    document.body.style.setProperty('--el-color-primary-light-3', '#66BBC7'); //鼠标放上按钮的颜色
    document.body.style.setProperty('--el-menu-active-color', '#0299B5'); //菜单激活的背景颜色

    let locale = zhCn
      return { locale };
  } 
}
</script>

<style>
#app {
  width:100%;
  height:100%;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  /* margin-top: 60px; */
}
</style>