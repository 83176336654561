import {createRouter ,createWebHashHistory} from 'vue-router'
import Home from '../components/Home.vue'
import Login from '../components/Login.vue'
import Console from '../components/Console.vue'
import RouteData from '../api/RouteData'
  
const router = createRouter({
    //内部提供了 history 模式的实现。我们在这里使用 hash 模式。
    history: createWebHashHistory(),
    routes:RouteData.createRoutes()   // `routes: routes` 的缩写
})

export default router