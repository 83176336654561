import CryptoJS from 'crypto-js'
import { asyncPostLoading } from './request';
import { behaviorStatisticsFn } from "@/filters/index.js"
// 加密
export function aesEncrypt(word) {
    let keyStr = 'a17d2850d832d0f5'; // 判断是否存在ksy，不存在就用定义好的key
    let key = CryptoJS.enc.Utf8.parse(keyStr)
    let srcs = CryptoJS.enc.Utf8.parse(word)
    let encrypted = CryptoJS.AES.encrypt(srcs, key, { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 })

    return CryptoJS.enc.Hex.stringify(encrypted.ciphertext);
}
// 解密
export function aesDecrypt(word) {
    let keyStr = 'a17d2850d832d0f5';
    let key = CryptoJS.enc.Utf8.parse(keyStr);
    let pTxt = CryptoJS.enc.Hex.parse(word);
    pTxt = CryptoJS.enc.Base64.stringify(pTxt);
    let decrypt = CryptoJS.AES.decrypt(pTxt, key, { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 })
    return decrypt.toString(CryptoJS.enc.Utf8);
}

export function endsWidth(str, suffix) {
    return str.indexOf(suffix, str.length - suffix.length) !== -1;
}

//数字转金额
export function formatNumber(number, prefix, decimals = 2) {
    const decPoint = '.';
    const thousandsSep = ',';
    number = (number + '').replace(/[^0-9+-Ee.]/g, '');
    let n = !isFinite(+number) ? 0 : +number;
    let prec = !isFinite(+decimals) ? 0 : Math.abs(decimals);
    let sep = (typeof thousandsSep === 'undefined') ? ',' : thousandsSep;
    let dec = (typeof decPoint === 'undefined') ? '.' : decPoint;
    let s = '';
    let toFixedFix = function (n, prec) {
        let k = Math.pow(10, prec);
        return '' + Math.ceil(n * k) / k;
    }
    s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.');
    let re = /(-?\d+)(\d{3})/;
    while (re.test(s[0])) {
        s[0] = s[0].replace(re, '$1' + sep + '$2')
    }
    if ((s[1] || '').length < prec) {
        s[1] = s[1] || '';
        s[1] += new Array(prec - s[1].length + 1).join('0');
    }
    return prefix + s.join(dec)
}


//格式化时间
export function dateFormatOfTable(row, column) {
    let date = row[column.property];
    if (!format) format = "yyyy-MM-dd HH:mm:ss";
    switch (typeof date) {
        case "string":
            date = new Date(date.replace(/-/, "/"));
            break;
        case "number":
            date = new Date(date);
            break;
        default:
            date = new Date(date);
            break;
    }
    date = new Date(date);
    if (!date instanceof Date) return;
    //console.log("**************** time:" + date);
    var dict = {
        "yyyy": date.getFullYear(),
        "M": date.getMonth() + 1,
        "d": date.getDate(),
        "H": date.getHours(),
        "m": date.getMinutes(),
        "s": date.getSeconds(),
        "MM": ("" + (date.getMonth() + 101)).substr(1),
        "dd": ("" + (date.getDate() + 100)).substr(1),
        "HH": ("" + (date.getHours() + 100)).substr(1),
        "mm": ("" + (date.getMinutes() + 100)).substr(1),
        "ss": ("" + (date.getSeconds() + 100)).substr(1)
    };
    return format.replace(/(yyyy|MM?|dd?|HH?|ss?|mm?)/g, function () {
        return dict[arguments[0]];
    });
}

//格式化时间
export function dateFormat(date, format) {
    if (!date) return;
    if (!format) format = "yyyy-MM-dd";
    switch (typeof date) {
        case "string":
            date = new Date(date.replace(/-/, "/"));
            break;
        case "number":
            date = new Date(date);
            break;
        default:
            date = new Date(date);
            break;
    }
    date = new Date(date);
    if (!date instanceof Date) return;
    //console.log("**************** time:" + date);
    var dict = {
        "yyyy": date.getFullYear(),
        "M": date.getMonth() + 1,
        "d": date.getDate(),
        "H": date.getHours(),
        "m": date.getMinutes(),
        "s": date.getSeconds(),
        "MM": ("" + (date.getMonth() + 101)).substr(1),
        "dd": ("" + (date.getDate() + 100)).substr(1),
        "HH": ("" + (date.getHours() + 100)).substr(1),
        "mm": ("" + (date.getMinutes() + 100)).substr(1),
        "ss": ("" + (date.getSeconds() + 100)).substr(1)
    };
    return format.replace(/(yyyy|MM?|dd?|HH?|ss?|mm?)/g, function () {
        return dict[arguments[0]];
    });
}

// 根据服务端返回的格式转为日期格式
export function formatDateTime(originalDateTimeString, format = 'YYYY-MM-DD HH:mm:ss') {
    const originalDate = new Date(originalDateTimeString);

    const year = originalDate.getFullYear();
    const month = (originalDate.getMonth() + 1).toString().padStart(2, '0');
    const day = originalDate.getDate().toString().padStart(2, '0');
    const hours = originalDate.getHours().toString().padStart(2, '0');
    const minutes = originalDate.getMinutes().toString().padStart(2, '0');
    const seconds = originalDate.getSeconds().toString().padStart(2, '0');

    const customFormat = format
        .replace('YYYY', year)
        .replace('MM', month)
        .replace('DD', day)
        .replace('HH', hours)
        .replace('mm', minutes)
        .replace('ss', seconds);

    return customFormat;
}

/*根据出生日期算出年龄*/
export function getAge(strBirthday) {

    let strBirthdayArr = strBirthday.split("-");
    let birthYear = strBirthdayArr[0];
    let birthMonth = strBirthdayArr[1];
    let birthDay = strBirthdayArr[2];

    let d = new Date();
    let nowYear = d.getFullYear();
    let nowMonth = d.getMonth() + 1;
    let nowDay = d.getDate();

    if (nowYear == birthYear) {
        return 0;//同年 则为0岁  
    }

    let ageDiff = nowYear - birthYear; //年之差  
    if (ageDiff > 0) {
        if (nowMonth == birthMonth) {
            let dayDiff = nowDay - birthDay;//日之差  
            if (dayDiff < 0) {
                return ageDiff - 1;
            } else {
                return ageDiff;
            }
        } else {
            let monthDiff = nowMonth - birthMonth;//月之差  
            if (monthDiff < 0) {
                return ageDiff - 1;
            } else {
                return ageDiff;
            }
        }
    }
    return 0;
}


//获取当前日期函数
export function getNowFormatDate() {
    let date = new Date(),
        year = date.getFullYear(), //获取完整的年份(4位)
        month = date.getMonth() + 1, //获取当前月份(0-11,0代表1月)
        strDate = date.getDate() // 获取当前日(1-31)
    if (month < 10) month = `0${month}` // 如果月份是个位数，在前面补0
    if (strDate < 10) strDate = `0${strDate}` // 如果日是个位数，在前面补0

    return `${year}-${month}-${strDate}`
}
/* 获取当月的第一天*/
export function getTheDayMonthStartAndEnd(nowDay) {
    let date = new Date(nowDay);
    date.setDate(1)
    date.toLocaleDateString(); //本月第一天
    let endDate = new Date(date.getFullYear(), date.getMonth() + 1, 0);//本月的最后一天

    let year = date.getFullYear(); //获取完整的年份(4位)
    let month = date.getMonth() + 1; //获取当前月份(0-11,0代表1月)
    let strDate = date.getDate(); // 获取当前日(1-31)
    if (month < 10) month = `0${month}` // 如果月份是个位数，在前面补0
    if (strDate < 10) strDate = `0${strDate}`; // 如果日是个位数，在前面补0

    let start = `${year}-${month}-${strDate}`;

    year = endDate.getFullYear(); //获取完整的年份(4位)
    month = endDate.getMonth() + 1; //获取当前月份(0-11,0代表1月)
    strDate = endDate.getDate(); // 获取当前日(1-31)
    if (month < 10) month = `0${month}` // 如果月份是个位数，在前面补0
    if (strDate < 10) strDate = `0${strDate}`; // 如果日是个位数，在前面补0

    let end = `${year}-${month}-${strDate}`;

    return { start: start, end: end };
}

// 过滤隐藏字符串部分
export function filterString(str, start = 0, end = 0) {
    if (!str) return '-'
    // 如果 start 和 end 参数没有传入或小于等于 0，则直接返回原字符串
    if (start <= 0 && end <= 0) return str;
    // 如果 start 参数小于 0，则将其设为 0
    if (start < 0) start = 0;

    const strLen = str.length;
    // 如果 end 参数小于 0，则将其设为字符串的长度
    if (end < 0) end = strLen;

    // 如果start 参数超过字符串长度，则输出完整字符串
    if (start > strLen) return str;
    // 如果end 参数超过字符串长度，则将其调整为字符串长度
    if (end > strLen) end = strLen;

    // 用 starChar 变量表示 str 的前 start 位，用 endChar 变量表示 str 的后 end 位，
    // 用 middleChar 变量表示 str 中间的 *** 部分
    const starChar = str.substr(0, start);
    const endChar = str.substr(strLen - end, end);
    const middleChar = '***';

    // 将各个部分拼接起来
    return start > 0 && end > 0
        ? starChar.concat(middleChar, endChar)
        : start > 0
            ? starChar.concat(middleChar)
            : end > 0
                ? middleChar.concat(endChar)
                : '';
}

// 根据id获取name值
export function getStatusNameById(id, statusList) {
    for (let i = 0; i < statusList.length; i++) {
        if (statusList[i].id === id) {
            return statusList[i].name;
        }
    }
    // 如果没有找到匹配的id，则返回null或者你认为合适的默认值
    return '';
}

// 判断是否为JSON
export function isJsonFn(str) {
    try {
        JSON.parse(str);
        return true;
    } catch (e) {
        return false;
    }
}

// 判断是否为正整数
export function isPositiveInteger(num) {
	return /^[1-9]\d*$/.test(num);
}

// 获取时间
export function getFormerlyMonth(val = 0) {// 过去val个月前
	const date = new Date(); // 获取当前日期
	date.setMonth(date.getMonth() - val); // 将月份减去val个月
	return date.getTime()
}

// 点击按钮添加数据
export function statisticsButtonApi(type, customerId) {
	if(!type) return
	let params = {
		customerId, // 用户id
		type, // 按钮类型
		typeName: behaviorStatisticsFn(type), // 按钮名字
	}
	console.log('params: ', params);
	let baseURL = ""
	if(process.env.NODE_ENV == "development") {
	  baseURL =  process.env.VUE_APP_BASE_API
	}else {
	  baseURL = process.env.VUE_APP_BASE_HOST || '';// 后面可能会拼接undefined,所以增加一个为空的判断
	}
	asyncPostLoading(baseURL + "/report-server/statistics/button", params);
	// request.post('report-server/statistics/button', params)
}


// 计算两个值相加
export function addValueFn(a, b) {
  return parseFloat(a || 0) + parseFloat(b || 0)
}

// 计算两个值相加
export function getAverageUnitCostFn(money, num) {
  const value = parseFloat(money || 0) / parseFloat(num || 0);
  return value ? value.toFixed(2) : 0;
}