<template>
    <div class="default">
        <el-container class="default">
            <el-header style="height:70px;"><v-head @onHospitalFn="onHospitalFn"></v-head></el-header>
			<iframe class="hospital-view" v-show="isHospital" :src="hospitalUrl" frameborder="0"></iframe>
            <el-container style="width:100%;height:calc(100% - 70px);" v-show="!isHospital">
                <el-aside width="200px"><v-leftMenu></v-leftMenu></el-aside>
                <el-container style="background:#D9D9D9;">
                    <v-tabs></v-tabs>
                    <el-main class="home-main">
                        <div class="content">
                            <router-view  v-slot="{ Component }">
                                <keep-alive>
                                    <component :is="Component" :key="$route.meta.id" v-if="$route.meta.keepAlive"></component>
                                </keep-alive>
                                <component :key="$route.meta.id" :is="Component" v-if="!$route.meta.keepAlive" />
                            </router-view>
                            <!-- <transition>
                                <keep-alive>
                                    <router-view/>
                                </keep-alive>
                            </transition> -->
                            <!-- <el-backtop target=".content"></el-backtop> -->
                            
                        </div>
                    </el-main>
                    <el-footer class="footer"> 浙江三生蔓迪药业有限公司 © {{new Date().getFullYear()}} 浙ICP备2021031497号-1 </el-footer>
                </el-container>
            </el-container>
        </el-container>
    </div>
</template>
<script>
import vHead from './Head.vue'
import vLeftMenu from './LeftMenu.vue'
import vTabs from './Tabs.vue'
import {Base64} from 'js-base64'
import UserStorage from '../api/UserStorage';
export default {
    components:{
        vHead,vLeftMenu,vTabs
    },
	data: function () {
		return {
			isHospital: false,
			hospitalUrl: process.env.VUE_APP_HOSPITAL_HOST
		}
	},
    computed: {
        //绑定唯一KEY，每次切换路由重新加载页面
        key() {
            let json = JSON.parse(Base64.decode(this.$route.query.data)); 
            return json.id; 
        },
        nickName(){
            return UserStorage.getUserInfo('nickName');
        },
        userName(){ 
            return UserStorage.getUserInfo('userName');
        },
        roleName(){ 
            return UserStorage.getUserInfo('roleName');
        }
    },
    watch: {
      nickName: {
        handler(newName, oldName) {
          this.setWatermarkFn();
        },
        immediate: true
      }
    },
    created(){
        
    },
	methods: {
		// 是否展示康怡邦互联网医院
		onHospitalFn(e) {
			this.isHospital = e;
		},
        // 水印
        setWatermarkFn() {
          if(this.nickName) {
            this.$watermark.set(`${this.nickName}  ${this.userName}`)
          }else {
            this.$watermark.set('')
          }
        },
	}
}
</script>
<style scoped>
.hospital-view {
	height: 100vh;
}
.default{
    margin:0;
    padding:0;
    width:100%;
    height:100%;
}
.home-main{
    height:calc(100% - 47px);
    /* background: url(../assets/background.png) no-repeat; */
}
.content{
    width: 100%;
    height: 100%;
    /* background: #FFFFFF; */
    /* border-radius: 5px; */
}
.footer{
    height:47px; 
    box-sizing: border-box;
  
    height: 47px; 
    line-height: 46px;
    background: #FFFFFF;  
    font-family: 'Abhaya Libre';
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    color: #606266; 
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); 
    bottom: 0;
}
</style>
